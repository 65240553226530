import React from "react"
import { Link } from  "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import VimeoPlayer from '../../components/vimeoplayer/vimeoplayer'
import { graphql } from "gatsby"


const IndexPage = ( { data } ) => (
  <Layout>
      {/*{ console.log(data.VideoBlockPages.edges) }*/}
      <SEO title="Assets" />

      <h1>A list of all content and tracked assets available</h1>

      <h2>Page Assets</h2>
      <ul>
          {data.allSitePage.nodes.map((page) => (
            <li key={page.path}><Link to={page.path}>{page.path}</Link></li>
          ))}
      </ul>

      <h2>Review Script PDF Assets</h2>
      <ul>
          {data.ReviewScriptPages.edges.map((script) => (
            <li key={script.node.frontmatter.script}><a href={script.node.frontmatter.script} target="_blank" rel="noopener noreferrer">{script.node.frontmatter.script}</a></li>
          ))}
      </ul>

      <h2>Tracked Video Assets</h2>
      <div>
          {data.VideoBlockPages.edges.map((video) => (
            <div key={video.node.url}>
                <h3>{video.node.title}</h3>
                <ul>
                    <li><a href={"//vimeo.com/" + video.node.videoid} target="_blank" rel="noopener noreferrer">Vimeo Link</a></li>
                    <li><Link to={video.node.url}>Local Link</Link></li>
                </ul>
                <br />
                <VimeoPlayer videoId={video.node.videoid} />
                <br /><br />
            </div>
          ))}
      </div>


  </Layout>
)

export default IndexPage

export const query = graphql`
query MyQuery {
  allSitePage(filter: {path: {regex: "/^(?!/test/).+?$/"}}) {
    nodes {
      path
    }
  }
  ReviewScriptPages: allMdx(filter: {fileAbsolutePath: {regex: "/(review-scripts)/"}}) {
    edges {
      node {
        frontmatter {
          script
        }
      }
    }
  }
  VideoBlockPages: allVideoblockpagesYaml {
    edges {
      node {
        videoid
        title
        url
      }
    }
  }
}

  `